import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import crypto from 'crypto-js';
import { jwtDecode } from 'jwt-decode';

import { AUTH } from '../../../config/api.config';
import {
  COOKIE_PREFIX,
  CRYPTO_SECRET_KEY,
} from '../../../environments/environment';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  localStorage: any = null;
  isRefreshing: any = false;
  authURLs: any[] = ['login', 'logout', 'refresh-tokens'];

  constructor(
    private httpService: HttpService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.localStorage = this.document?.defaultView?.localStorage || null;
  }

  /** Login Post Api */
  login(body: any) {
    return this.httpService.post(AUTH.LOGIN, body, true, false);
  }

  /**Refresh Token Api */
  refreshToken() {
    return this.httpService.post(AUTH.REFRESH_TOKEN, {
      refreshToken: this.getLocalstorage('refreshToken'),
    });
  }

  /**Logout Api*/
  logoutUser() {
    return this.httpService.post(AUTH.LOGOUT, {
      refreshToken: this.getLocalstorage('refreshToken'),
    });
  }

  /**Registration API */
  userRegistration(userDetail: any) {
    return this.httpService.post(AUTH.REGISTRATION, userDetail, true, false);
  }

  /**Verify OTP API */
  verifyOtp(otp: any, userDetail: any) {
    return this.httpService.patch(
      `${AUTH.VERIFY_OTP}/${otp}`,
      userDetail,
      true,
      false,
    );
  }

  /**Resend OTP API */
  resendOtp(userDetail: any) {
    return this.httpService.patch(AUTH.RESEND_OTP, userDetail, true, false);
  }

  /**Verify OTP API */
  updateUserDetail(userId: any, userDetail: any) {
    return this.httpService.put(
      `${AUTH.UPDATE_USER}/${userId}`,
      userDetail,
      true,
      false,
    );
  }

  /**Verify OTP API */
  addPaymentPlan(planDetail: any) {
    return this.httpService.post(AUTH.PAYMENT_PLAN, planDetail, true, false);
  }

  /**Verify OTP API */
  getSubscriptionList(queryParams: any) {
    return this.httpService.get(
      AUTH.GET_SUBSCRIPTION,
      queryParams,
      false,
      false,
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    this.localStorage?.setItem('accessToken', token);
  }

  get accessToken(): string {
    return this.localStorage?.getItem('accessToken') ?? '';
  }

  setLocalStorage(key: any, value: any) {
    this.localStorage?.setItem(`${COOKIE_PREFIX}_${key}`, value);
  }

  getLocalstorage(key: any): string {
    return this.localStorage?.getItem(`${COOKIE_PREFIX}_${key}`) ?? '';
  }

  removeLocalStorage(key: any) {
    this.localStorage?.removeItem(`${COOKIE_PREFIX}_${key}`);
  }

  /**
   * setAccessRefreshToken - This function set the access and refresh token
   * @param tokenData is the token data from get the login api
   */
  setAccessRefreshToken(tokenData: any) {
    this.localStorage?.setItem(
      `${COOKIE_PREFIX}_accessToken`,
      tokenData?.access?.token,
    );
    this.localStorage?.setItem(
      `${COOKIE_PREFIX}_refreshToken`,
      tokenData?.refresh?.token,
    );
    this.isRefreshing = false;
  }

  /**
   * decodeJwtToken - This function decode the jwt token
   * @param token is the encrypted token
   * @returns decoded token
   */
  decodeJwtToken(token: any) {
    if (token) {
      return jwtDecode(token);
    }
    return null;
  }

  /**
   * isTokenValid - This function check the token expire or not
   * @param type is the token type access or refresh token
   * @returns if the token not expire then return the true and if the expire then return false value
   */
  isTokenValid(type: any): boolean {
    let result = false;
    const token = this.getLocalstorage(type);
    if (token) {
      const tokenData = this.decodeJwtToken(token);
      if (tokenData && tokenData?.exp) {
        const currentTime = Math.floor(new Date().getTime() / 1000);
        if (tokenData.exp <= currentTime) {
          result = false;
        } else {
          result = true;
        }
      }
    }
    return result;
  }

  /**
   * generateAccessToken - This function call the refresh token and set the local storage
   * @returns
   */
  generateAccessToken() {
    return new Promise((resolve) => {
      try {
        this.refreshToken().subscribe({
          next: (response) => {
            if (response && response?.data) {
              this.setAccessRefreshToken(response?.data);
              resolve(true);
            } else {
              resolve(false);
            }
          },
          error: async () => {
            //logout
            resolve(false);
          },
        });
      } catch (error) {
        console.log('error', error);
        resolve(false);
      }
    });
  }

  /**
   * Decrypted User Details
   * @returns
   */
  decryptedUserDetails() {
    const userData = this.getLocalstorage('userDetails');
    let decryptedData = null;
    if (userData) {
      const bytes = crypto.AES.decrypt(userData, CRYPTO_SECRET_KEY);
      decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
    }
    return decryptedData;
  }

  /**
   * setUserDetails - This function encrypt the user details and set to the localStorage
   * @param userDetails
   */
  setUserDetails(userDetails: any) {
    const encryptedUserDetails = crypto.AES.encrypt(
      JSON.stringify(userDetails),
      CRYPTO_SECRET_KEY,
    ).toString();

    this.setLocalStorage('userDetails', encryptedUserDetails);
  }

  async checkToken() {
    if (this.getLocalstorage('refreshToken')) {
      if (this.isTokenValid('refreshToken')) {
        if (this.isTokenValid('accessToken')) {
          return true;
        } else {
          const result: any = await this.generateAccessToken();
          if (!result) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        //logout
        return false;
      }
    } else {
      this.router.navigateByUrl(`auth/sign-in`);
      return false;
    }
  }

  getRegistrationDetail() {
    const userDetail = this.decryptedUserDetails();
    if (userDetail) {
      if (userDetail?.step === 1) {
        this.router.navigate(['/auth/user-details']);
      } else if (userDetail?.step === 2) {
        this.router.navigate(['/auth/questions']);
      } else if (userDetail?.step === 3) {
        this.router.navigate(['/auth/subscription']);
      }
    }else{
      this.router.navigate(['/auth/login']);
    }
  }
  isRegistrationCompleted() {
    const userDetail = this.decryptedUserDetails();
    if (userDetail) {
      return userDetail?.step === 4 ? true : false;
    } else {
      return false;
    }
  }

  clearLocalStorage() {
    this.removeLocalStorage('userDetails');
    this.removeLocalStorage('accessToken');
    this.removeLocalStorage('refreshToken');
  }

  /**
   * logout - This function call the logout api and remove the access and refresh token
   */
  logout() {
    return new Promise((resolve) => {
      try {
        this.logoutUser().subscribe({
          next: () => {
            this.localStorage.clear();
            this.router.navigateByUrl(`auth/login`);
            resolve(true);
          },
          error: () => {
            this.localStorage.clear();
            this.router.navigateByUrl(`auth/login`);
            resolve(true);
          },
        });
      } catch (error) {
        localStorage?.clear();
        this.router.navigateByUrl(`auth/login`);
        resolve(true);
      }
    });
  }
}
