import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, catchError, map, throwError } from "rxjs";
import { GenerateToastrService } from "./generate-toastr.service";
// import { GenerateToasterService } from './generate-toaster.service';

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(
    private httpClient: HttpClient,
    private generateToastrService: GenerateToastrService
  ) {}

  /**
   * Http POST request handler
   */
  public post(
    url: string,
    data: any,
    flashFlag = false,
    isFlashStop = true
  ): Observable<any> {
    return this.httpClient.post(url, data).pipe(
      map((res: any) => {
        if (flashFlag && !isFlashStop) {
          this.successResponseToast(res);
        }
        return res;
      }),
      catchError((error: any) => {
        this.commonErrorHandler(error, isFlashStop);
        return throwError(() => error?.error);
      })
    );
  }

  /**
   * Http GET request handler
   */
  public get(
    url: string,
    params: any = {},
    flashFlag = false,
    isFlashStop = true
  ): Observable<any> {
    return this.httpClient
      .get(url, {
        params: params,
      })
      .pipe(
        map((res: any) => {
          if (flashFlag && !isFlashStop) {
            this.successResponseToast(res);
          }
          return res;
        }),
        catchError((error: any) => {
          this.commonErrorHandler(error, isFlashStop);
          return throwError(() => error?.error);
        })
      );
  }

  /**
   * Http GET request handler for get Documents. Example: PDF, etc.
   */
  public getDocument(
    url: string,
    params: any = {},
    flashFlag = false,
    isFlashStop = true
  ): Observable<any> {
    return this.httpClient
      .get(url, {
        params: params,
        responseType: "blob" as "json",
        observe: "response" as "body",
      })
      .pipe(
        map((res: any) => {
          if (flashFlag && !isFlashStop) {
            this.successResponseToast(res);
          }
          return res;
        }),
        catchError((error: any) => {
          this.commonErrorHandler(error, isFlashStop, true);
          return throwError(() => error?.error);
        })
      );
  }

  /**
   * Http PUT request handler
   */
  public put(
    url: string,
    data: any,
    flashFlag = false,
    isFlashStop = true
  ): Observable<any> {
    return this.httpClient.put(url, data).pipe(
      map((res: any) => {
        if (flashFlag && !isFlashStop) {
          this.successResponseToast(res);
        }
        return res;
      }),
      catchError((error: any) => {
        this.commonErrorHandler(error, isFlashStop);
        return throwError(() => error?.error);
      })
    );
  }

  /**
   * Http PATCH request handler
   */
  public patch(
    url: string,
    data: any,
    flashFlag = false,
    isFlashStop = true
  ): Observable<any> {
    return this.httpClient.patch(url, data).pipe(
      map((res: any) => {
        if (flashFlag && !isFlashStop) {
          this.successResponseToast(res);
        }
        return res;
      }),
      catchError((error: any) => {
        this.commonErrorHandler(error, isFlashStop);
        return throwError(() => error?.error);
      })
    );
  }

  /**
   * Http DELETE request handler
   */
  public delete(
    url: string,
    flashFlag = false,
    isFlashStop = true
  ): Observable<any> {
    return this.httpClient.delete(url).pipe(
      map((res: any) => {
        if (flashFlag && !isFlashStop) {
          this.successResponseToast(res);
        }
        return res;
      }),
      catchError((error: any) => {
        this.commonErrorHandler(error, isFlashStop);
        return throwError(() => error?.error);
      })
    );
  }

  /**
   * Common Error Handler
   */
  private commonErrorHandler(
    error: any,
    isFlashFlag = true,
    isFromGetDocument = false
  ) {
    // console.log(`isFlashStop :>> `, isFlashFlag);
    if (
      !isFlashFlag &&
      (error?.error?.message || error?.error?.messageType != "warning")
    ) {
      const message =
        error?.error?.message ||
        (isFromGetDocument
          ? "Requested file not found!"
          : "Something went wrong!");
      this.generateToastrService.error(message);
    }
  }

  /**
   * Success Response Toast
   * @param response
   */
  private successResponseToast(response: any) {
    this.generateToastrService.success(response?.message);
  }
}
