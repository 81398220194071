export const environment = {
  production: true,
};

export const API_PREFIX = `https://api.ima.demoserver.biz`;
// export const API_PREFIX = `http://192.168.1.38:3001`;

export const COOKIE_PREFIX = 'ima';
export const COOKIE_DOMAIN = 'https://ima.projectdemo.in';
export const SOCKET_END_POINT = '';
export const SOCKET_SERVICE_ID = '';
export const CRYPTO_SECRET_KEY = 'Qwhuqweohdh23434@sjndnd';
