import { API_PREFIX } from '../environments/environment';

/* Authentication API List. */
export const AUTH = {
  LOGIN: `${API_PREFIX}/v1/auth/login`,
  LOGOUT: `${API_PREFIX}/v1/auth/logout`,
  REFRESH_TOKEN: `${API_PREFIX}/v1/auth/refresh-tokens`,
  // FORGOT_PASSWORD: `${API_PREFIX}/v1/admin/forgot-password`,
  // RESET_PASSWORD: `${API_PREFIX}/v1/admin/reset-password`,
  REGISTRATION: `${API_PREFIX}/v1/auth/users`,
  VERIFY_OTP: `${API_PREFIX}/v1/auth/users/verify-otp`,
  RESEND_OTP: `${API_PREFIX}/v1/auth/users/resend-otp`,
  UPDATE_USER: `${API_PREFIX}/v1/auth/users`,
  PAYMENT_PLAN: `${API_PREFIX}/v1/auth/payments`,
  GET_SUBSCRIPTION: `${API_PREFIX}/v1/subscription/list`,
};

export const WATCH_LIST = {
  GET_WATCH_LIST: `${API_PREFIX}/v1/auth/watchlists/list`,
  GET_WATCH_LIST_BY_ID: `${API_PREFIX}/v1/auth/watchlists`,
  ADD_WATCH_LIST: `${API_PREFIX}/v1/auth/watchlists`,
  UPDATE_WATCH_LIST: `${API_PREFIX}/v1/auth/watchlists`,
  UPDATE_TICKER: `${API_PREFIX}/v1/auth/watchlists`,
  DELETE_WATCH_LIST: `${API_PREFIX}/v1/auth/watchlists`,
  SEARCH_STOCK: `${API_PREFIX}/v1/auth/tickers/search`,
};

export const PORTFOLIO = {
 ADD_PORTFOLIO:`${API_PREFIX}/v1/auth/portfolios`,
 PORTFOLIO_LIST:`${API_PREFIX}/v1/auth/portfolios/list`,
 ADD_STOCK:`${API_PREFIX}/v1/auth/portfolio-stock`,
};
